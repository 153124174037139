import React from "react";
import MbHeader, { HeaderSmall as MbHeaderSmall } from "mibaby-frontend/layout/Header";
import useMenu from "mibaby-app-page-builder/site/util/hooks/useMenu";
import type { LinkComponent, MenuItem } from "mibaby-frontend/layout/Menu";
import { useLocation } from "@webiny/react-router";
import { useTopBarPromotions } from "mibaby-app/util/hook/useStaticContent";
// import { useI18nContext } from "mibaby-app/contexts/I18n";

const DEFAULT_MENU_NAME = "main-menu";
const DEFAULT_MENU_NAME_FR = "main-menu-fr";
const DEFAULT_MENU_NAME_IT = "main-menu-it";
const DEFAULT_MENU_NAME_EN = "main-menu-gb";
const DEFAULT_MENU_NAME_US = "main-menu-us";
const DEFAULT_MENU_NAME_ES = "main-menu-es";
const DEFAULT_MENU_NAME_NL = "main-menu-nl";
const DEFAULT_MENU_NAME_PT = "main-menu-pt";
const DEFAULT_MENU_NAME_PL = "main-menu-pl";
const DEFAULT_MENU_NAME_TR = "main-menu-tr";
const DEFAULT_MENU_NAME_CZ = "main-menu-cz";
const DEFAULT_MENU_NAME_DK = "main-menu-dk";

export type HeaderProps = {
    homepage?: string;
    menuName?: string;
    Link?: LinkComponent;
};

// TODO: So V2 links work
const DefaultLink: LinkComponent = ({ to, ...props }) => <a href={to} {...props} />;

export function Header({ homepage = "/", menuName, Link = DefaultLink }: HeaderProps) {
    const { pathname, search } = useLocation();
    const query = new URLSearchParams(search);
    const nocache = query.has("force") || query.has("nocache");
    // const i18n = useI18nContext();
    // const locale = i18n.getLocale();
    // console.log("locale", locale);
    let menu = menuName;

    let siteLanguage = "de";

    const tld = typeof window !== "undefined" ? window.location?.hostname?.split(".").pop() : "";

    if (tld === "fr") {
        siteLanguage = "fr";
    } else if (tld === "it") {
        siteLanguage = "it";
    } else if (tld === "eu") {
        siteLanguage = "es";
    } else if (tld === "nl") {
        siteLanguage = "nl";
    } else if (tld === "be") {
        siteLanguage = "nl";
    } else if (tld === "io") {
        siteLanguage = "en";
    } else if (tld === "us") {
        siteLanguage = "us";
    } else if (tld === "pt") {
        siteLanguage = "pt";
    } else if (tld === "club") {
        siteLanguage = "pt";
    } else if (tld === "pl") {
        siteLanguage = "pl";
    } else if (tld === "tr") {
        siteLanguage = "tr";
    } else if (tld === "cz") {
        siteLanguage = "cz";
    } else if (tld === "dk") {
        siteLanguage = "da";
    }

    if (!menu) {
        if (siteLanguage === "it") {
            menu = DEFAULT_MENU_NAME_IT;
        } else if (siteLanguage === "fr") {
            menu = DEFAULT_MENU_NAME_FR;
        } else if (siteLanguage === "es") {
            menu = DEFAULT_MENU_NAME_ES;
        } else if (siteLanguage === "en") {
            menu = DEFAULT_MENU_NAME_EN;
        } else if (siteLanguage === "us") {
            menu = DEFAULT_MENU_NAME_US;
        } else if (siteLanguage === "nl") {
            menu = DEFAULT_MENU_NAME_NL;
        } else if (siteLanguage === "be") {
            menu = DEFAULT_MENU_NAME_NL;
        } else if (siteLanguage === "pt") {
            menu = DEFAULT_MENU_NAME_PT;
        } else if (siteLanguage === "pl") {
            menu = DEFAULT_MENU_NAME_PL;
        } else if (siteLanguage === "tr") {
            menu = DEFAULT_MENU_NAME_TR;
        } else if (siteLanguage === "cz") {
            menu = DEFAULT_MENU_NAME_CZ;
        } else if (siteLanguage === "da") {
            menu = DEFAULT_MENU_NAME_DK;
        } else {
            menu = DEFAULT_MENU_NAME;
        }
    }

    const { items: menuItems = [] } = useMenu({ slug: menu }) || {};
    const { data: topBarConfigs } = useTopBarPromotions({ variables: { nocache } });

    return (
        <MbHeader
            path={pathname}
            homepage={homepage}
            classPrefix="webiny-pb-section"
            contentContainer=".webiny-pb-page"
            menuItems={menuItems as MenuItem[]}
            topBarConfigs={topBarConfigs}
            Link={Link}
        />
    );
}

export function HeaderSmall({
    homepage = "/",
    Link = DefaultLink,
}: Pick<HeaderProps, "homepage" | "Link">) {
    return <MbHeaderSmall homepage={homepage} Link={Link} classPrefix="webiny-pb-section" />;
}

export default Header;
